.navbar-wrapper {
}

.navbar-items {
  padding: 0 8% !important;
  background-color: #cc6405;
  color: black;
}

.comuna-logo {
  /* width: 100px; */
  height: 100px;
}

.navbar-collapse {
  justify-content: end;
}

.topnav-link {
  text-decoration: none;
  font-size: 16px;
  font-weight: 700;
  color: black;
  margin: 0 20px;
}

.topnav-link:hover {
  /* border-bottom: 0.5rem solid #2c0361 !important ; */
  color: #fff;
}

@media only screen and (max-width:768px){
  .comuna-logo{
    height: auto;
    width: 100%;
  }
}