.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.undermaintenance {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 10%;
  overflow: hidden;
}

.text {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: start;
}

.oops {
  color: #be5d04;
  font-size: 70px;
  font-weight: bold;
  margin: 0 0 2rem 0;
}

.maintenance {
  color: #0c629f;
  font-size: 30px;
  font-weight: 700;
  margin: 0;
}

.image {
  width: 50%;
  text-align: end;
  right: 0;
}

.main-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media only screen and (max-width: 768px) {
  .undermaintenance {
    flex-direction: column;
    margin-top: 30px;
    align-items: center;
  }

    .text{
      align-items: center;
    }
    
  
  .oops {
    font-size: 35px;
    margin-bottom: 1rem;
    

  }
  .maintenance {
    font-size: 22px;
    margin-top: 0;
    margin-bottom: 0;
  }
  .image{
    width: 100%;
  }
}
